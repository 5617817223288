import { render, staticRenderFns } from "./ExamAsDistToRegList.vue?vue&type=template&id=04259035&scoped=true&"
import script from "./ExamAsDistToRegList.vue?vue&type=script&lang=js&"
export * from "./ExamAsDistToRegList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04259035",
  null
  
)

export default component.exports